import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Pagination from '../components/Pagination'
import LBNavigation from '../components/LBNavigation'
import LBCard from '../components/LBCard'
import SEO from '../components/SEO'
import { startCase } from 'lodash'

const Posts = ({ data, pageContext }) => {
  const posts = data.allContentfulPost.edges
  const { humanPageNumber, basePath } = pageContext
  const isFirstPage = humanPageNumber === 1
  let featuredPost
  let ogImage


  console.log(basePath);

  try {
    featuredPost = posts[0].node
  } catch (error) {
    featuredPost = null
  }
  try {
    ogImage = posts[0].node.heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  return (
    <div className="bg-index-articoli">
      <LBNavigation isHome={false}/>
      <Layout>
        <SEO title={startCase(basePath)} image={ogImage} />
        <div className="title-eyebrow eyebrow-articoli"><Link to="/">Home </Link> / Articoli</div>
        <h1 className="title articoli">Articoli</h1>
        <div className="articles-wrapper row no-gutters justify-content-center">
          {posts.map(({ node: post}, index) => (
            <LBCard key={post.id} post={post} page="articoli" articleNumber={index+1} basePath={basePath}/>
          ))}
        </div>
        <div className="tutti-gli-articoli-cta">
          <Pagination context={pageContext} />
        </div>
      </Layout>
      <div className="articoli-bg"></div>
    </div>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "DD MMMM YYYY", locale: "it")
          tags {
            title
            id
            slug
          }
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            ogimg: resize(width: 1800) {
              src
            }
          }
          body {
            childMarkdownRemark {
              timeToRead
              html
              excerpt(pruneLength: 160)
            }
          }
        }
      }
    }
  }
`

export default Posts
