import React from 'react'
import { navigate, Link } from 'gatsby'


const Pagination = props => {
  function changePage(e) {
    navigate(
      e.target.value
        ? `${props.context.paginationPath}/${e.target.value}`
        : `${props.context.paginationPath}/`
    )
  }

  return (
    <>
      {props.context.numberOfPages > 1 && (
        <div className="pagination-wrapper">
          <div>
              <Link 
                to={`${props.context.previousPagePath}`} 
                className={props.context.previousPagePath ? "pagination-prev-link" : "pagination-prev-link inactive"}>
                <span>&larr;</span>
              </Link>
            <span className="pagination-numbers">
              <span className="pagination-page-number">{props.context.humanPageNumber}</span>{' '}
              <select className=""
                value={
                  props.context.humanPageNumber === 1
                    ? ``
                    : props.context.humanPageNumber
                }
                onChange={changePage}
              >
                {Array.from({ length: props.context.numberOfPages }, (_, i) => (
                  <option value={`${i === 0 ? `` : i + 1}`} key={`page${i + 1}`}>
                    {i + 1}
                  </option>
                ))}
              </select>
              di {props.context.numberOfPages}
            </span>
              <Link style={{ order: 3 }} 
                className={props.context.nextPagePath ? "pagination-next-link" : "pagination-next-link inactive"} 
                to={`${props.context.nextPagePath}`}>
                <span>&rarr;</span>
              </Link>
          </div>
        </div>
      )}
    </>
  )
}

export default Pagination
